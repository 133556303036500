export const cities = [
    { nazov: 'Beňadiková', cena: 4.00},
    { nazov: 'Bobrovček', cena: 4.00},
    { nazov: 'Bobrovec', cena: 4.00},
    { nazov: 'Demänovská dolina (Jasná)', cena: 5.00},
    { nazov: 'Galovany', cena: 4.00},
    { nazov: 'Gôtovany', cena: 5.00},
    { nazov: 'Jakubovany', cena: 4.00},
    { nazov: 'Jalovec', cena: 4.00},
    { nazov: 'Jamník', cena: 5.00},
    { nazov: 'Konská', cena: 5.00},
    { nazov: 'Kvačany', cena: 5.00},
    { nazov: 'Liptovská Ondrášová', cena: 0.00},
    { nazov: 'Liptovská Porúbka', cena: 5.00},
    { nazov: 'Liptovská Sielnica', cena: 5.00},
    { nazov: 'Liptovské Matiašovce', cena: 5.00},
    { nazov: 'Liptovský Hrádok', cena: 5.00},
    { nazov: 'Liptovský Ján', cena: 4.00},
    { nazov: 'Liptovský Mikuláš', cena: 0.00},
    { nazov: 'Liptovský Mikuláš - Bodice', cena: 3.00},
    { nazov: 'Liptovský Mikuláš - časť Podbreziny', cena: 0.00},
    { nazov: 'Liptovský Mikuláš - Demänová', cena: 3.00},
    { nazov: 'Liptovský Mikuláš - Tatralandia', cena: 3.00},
    { nazov: 'Liptovský Ondrej', cena: 4.00},
    { nazov: 'Liptovský Peter', cena: 5.00},
    { nazov: 'Liptovský Trnovec', cena: 4.00},
    { nazov: 'Ľubeľa', cena: 5.00},
    { nazov: 'Okoličné', cena: 0.00},
    { nazov: 'Partizánska Ľupča', cena: 5.00},
    { nazov: 'Pavčina Lehota', cena: 4.00},
    { nazov: 'Pavlova Ves', cena: 4.00},
    { nazov: 'Ploštín / Iľanovo', cena: 3.00},
    { nazov: 'Podtureň', cena: 4.00},
    { nazov: 'Smrečany', cena: 4.00},
    { nazov: 'Svätý kríž', cena: 4.00},
    { nazov: 'Trstené', cena: 4.00},
    { nazov: 'Uhorská Ves', cena: 4.00},
    { nazov: 'Vavrišovo', cena: 5.00},
    { nazov: 'Závažná poruba', cena: 4.00},
    { nazov: 'Žiar', cena: 4.00}
]